.coming-soon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    &__icon{
        font-size: 40px;
        color: black
    }
    p{
        color: black;
    }

}