.whatsapp{
    &-btn{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 20px;
       left: 20px;
        z-index: 150;
    }
    &__icon{
       height: 60px;
       filter: drop-shadow(0px 0px 6px white);
       transition: all 0.4s ease-in;
       &:hover{
        height: 70px;
       }
    }
}