.portfolio {
  &__block {
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;


  }

  &__boat {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fboats-desktop.jpg?alt=media&token=4bbdc032-dd3d-47c4-acfb-e96a7d34a291');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    opacity: 0.25;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.4s ease all;
    &:hover{
      opacity: 0.5;
    }
  }

  &__car {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2Fgalleryhome-2.jpg?alt=media&token=ed2ea9bc-28dd-40da-950e-7ade59537236');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    opacity: 0.35;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease all;
    &:hover{
      opacity: 0.5;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__subtitle {
    text-transform: uppercase;
    color: white;
  }

  @media (max-width:675px) {
    &__boat {
      background-image: url('https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fboat-mobile.jpeg?alt=media&token=cc2048fe-586a-4103-8c95-958ab26a5678')
    }
    &__car{
      background-image: url('https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2Fcar-mobile.jpeg?alt=media&token=5bd4ba47-d7c6-4009-8df6-aabcea18665a');
    }
  }
}