.aboutus {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 117px;

  &__mission {
    width: 40%;
    height: 50vh;
    border-radius: 10px;
    background-color: white;
    transition: background-color 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      background: url("https://dercocenter-api.s3.us-east-1.amazonaws.com/images/contents/2021-08-25-car-detailing.jpg");
      background-size: cover;
      color: white;
    }
  }
  &__description {
    width: 80%;
  }
  &__vision {
    width: 40%;
    height: 50vh;
    border-radius: 10px;
    flex-direction: column;
    background-color: white;
    transition: background-color 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: url("https://hmcarandboatdetailing.com/wp-content/uploads/2020/03/HM-Car-and-Boat-Detailing.jpg");
      background-size: cover;
      color: white;
    }
  }
  &__subtitle {
    font-size: 36px;
    text-transform: uppercase;
  }

  &__block2 {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  @media (max-width: 675px) {
    padding-top: 150px;
    height: auto;
    &__block2 {
      height: auto;
    }
  }
  &__block1 {
    height: 100vh;
    display: flex;
    padding: 0px 10px 0px 10px;
    align-items: center;
    justify-content: center;

    @media (max-width: 675px) {
      height: auto;
    }
  }
  &__block {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: linear-gradient(135deg, #520000, #8b0000, #520000);

    @media (max-width: 1056px) {
      border-radius: 10px;
      height: auto;
    }
  }
  &__image {
    max-width: 350px;
    min-width: 500px;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 8px rgb(14, 14, 14));
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    flex-wrap: wrap;
    border-radius: 5px;
  }
  &__text {
    width: 50%;
    text-align: center;
    color: white;
    font-weight: 300;
    margin-left: 20px;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  &__title {
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 30px;

    h2 {
      margin: 0;
      color: rgb(255, 255, 255);
      text-shadow: 2px 2px 6px rgb(121, 121, 121);
      letter-spacing: 2px;
      font-size: 40px;
    }
  }

  @media (max-width:1056px) {
    padding-top: 120px;
    &__subtitle{
      font-size: 24px;
    }
    &__text{
      margin-left: 0;
      width: 90%;
    }
  }
}
