.services-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: auto;
  padding-top: 130px;

  &__text{
    width: 80%;
  }
  &__title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;

  }
  &__block1 {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    background:url('https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/boats%2F20230329_184619.jpg?alt=media&token=58d3d6eb-c129-42f1-843e-4d32d0a6ea03');
    background-position: center;
    background-size: cover;
  }
  &__description {
    max-width: 1200px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__list {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    padding: 0;
    max-width: 1000px;
  }
  &__element {
    text-decoration: none;
    margin: 10px;
    border-radius: 10px;
    padding: 20px;
    background: linear-gradient(
      225deg,
      rgba(175, 175, 175, 0.75) ,
      rgba(248, 248, 248, 0.75),
      rgba(175, 175, 175, 0.75) ,
    );
    border: 1px solid rgb(182, 182, 182);
    font-weight: 500;
    color: rgb(39, 39, 39);
    text-transform: uppercase;
  }

  &__block2{
    height: 100vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background: url('https://firebasestorage.googleapis.com/v0/b/vip-hamptons-detailing.appspot.com/o/cars%2F20160625_172435.jpg?alt=media&token=e0db9346-48ed-4e4c-ae5d-bb6fef45789b');
    background-size: cover;
    background-position: center;
  }
  &__subtitle{
    font-weight: 400;
    font-size: 40px;
    color: white;
    filter: drop-shadow(2px 2px 6px black);
  }
}
