.page-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__description{
    width: 90%;
    text-align: justify;
    text-transform: capitalize;
    font-size: 24px;
  }
  &__side1{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__side2 {
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  @media (max-width:1065px) {
    flex-direction: column;
    &__description{
      font-size: 16px;
      font-weight: 300;
    }
  }
}
