.header {
    position: fixed;
    width: 100%;
    z-index: 100;
    
    &__phone{
      background: #FF0000;
      padding: 2px;

      p{
        margin: 0;
        color: white;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 14px;
      }
    }

    &__user{
      color: white;
      font-size: 26px;
      margin-left: 10px;

    }
    &-menu { 
      z-index: 150;
     
    }
    &-block {
      background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(181,180,180,1) 22%, rgba(158,157,157,1) 24%, rgba(73,73,73,1) 42%, rgba(65,65,65,1) 55%, rgba(132,132,132,1) 72%, rgba(148,148,148,1) 74%, rgba(83,83,83,1) 83%, rgba(0,0,0,1) 100%);
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 700;
      position: relative;
    }
    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 250px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 6px;
      h2{
        font-size: 16px;
        text-transform: uppercase;
        text-align: right;
        font-weight: 400;
        color:  white;
        padding-right: 10px;
      }
      img{
        height: 70px;
        padding: 10px 0px 10px 0px;
        filter: drop-shadow(0px 0px 4px white);

        @media (max-width: 475px) {
          height: 60px;
        }
      }
     
    }
    &-icons {
      display: flex;
      width: 100px;
      align-items: center;
      justify-content: center;
      
    
    }
    &-icons__market {
      height: 30px;
      cursor: pointer;
    }
    &-icons__language {
      color: white;
      margin-right: 20px;
      font-size: 26px;
    }
    &-nav {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      width: 50%;
      height: 100vh;
      position: absolute;
      transition: all 0.4s ease-in-out;
      top: 0;
      z-index: 90;
      background: #232526b9;
      background-size: cover;
      backdrop-filter: blur(5px);
      text-transform: uppercase;
      padding-top: 40px;
  
      &__icons {
        transition: all 0.3s ease;
        &:hover{
          filter: drop-shadow(0px 0px 8px white);
        }
      }
    }
    &-nav__sections {
      display: flex;
      flex-direction: column;
      padding: 40px 0px 40px 0px;
      width: 100%;
    }
    &-nav__links {
      text-decoration: none;
      color: white;
      padding: 20px 0px 20px 0px;
      letter-spacing: 3px;
      width: 100%;
      font-weight: 300;
      transition: all 0.4s ease-in-out;
  
      &:hover {
        font-weight: 500;
        background: rgba(255, 255, 255, 0.815);
        color:#1f1c2c;
      }
    }
    &__submenu {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
  
      &__title {
        text-decoration: none;
        padding-top: 10px;
        color: rgba(255, 255, 255, 0.836);
        margin: 0;
        height: 30px;
        letter-spacing: 3px;
        width: 100%;
        transition: all 0.4s ease-in-out;
        
        &:hover{
          font-weight: 600;
          background: rgba(189, 189, 189, 0.815);
          color: rgb(0, 0, 0);
        }
      }
      &__links {
        text-decoration: none;
        margin: 0;
        padding: 0;
        color: white;
        padding: 10px 0px 10px 0px;
        letter-spacing: 3px;
        font-size: 14px;
        width: 100%;
        transition: all 0.4s ease-in-out;
  
        &:hover {
          font-weight: 600;
          background: rgba(255, 255, 255, 0.815);
          color: rgb(0, 0, 0);
        }
      }
    }
    @media (max-width: 1080px) {

      &-nav {
        width: 100%;
        height: 100vh;
  
        &__icons {
          padding: 0;
        }
      }
    }
}

.slide-in {
  transform: translateX(0%);
}

.slide-out {
  transform: translateX(-100%);
}