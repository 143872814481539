.cardetailing {
  &__block {
    padding-top: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    font-size: 36px;
    background: #3e4041;
    color: rgb(201, 201, 201);
    width: 100%;
    margin: 0;
    padding-top: 60px;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-weight: 300;
  }
}
