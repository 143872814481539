.boatdetailing{
    &__block{
        padding-top: 80px;
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: center;
    }
}
.image-gallery-fullscreen-button {
    display: flex;
    position: absolute;

  }

  .image-gallery-play-button{
    display: flex;
    position: absolute;
  }

  .image-gallery-image {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }