.image-label {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__image {
    width: 100%;
    object-fit: cover;
    
  }
  &__title {
    position: absolute;
  }
}
