.home {
  &__block {
    display: flex;
    padding-top: 80px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  &__title {
    position: absolute;
    font-family: "Square Peg", cursive;
    font-weight: 400;
    filter: drop-shadow(2px 2px 6px black);
    right: 20px;
    bottom: 20px;
    color: white;
    font-size:100px;
    text-transform: capitalize;
  }
}
.image-gallery-slide {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateY(30px);
}

.image-gallery-icon {
  display: none;
}

.image-gallery-fullscreen-button {
  display: none;
}

@media (max-width: 767px) {
  .image-gallery-image img {
    width: 100vw;
    height: calc(100vh - 80px);
    object-fit: cover;
    transform: none;
  }
}

/* Mixin para tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .image-gallery-image img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transform: none;
  }
}
